@use "sass:meta" as ---4uev11z1pob;.dispute-card-outer{
    .card-title{
        background: #F4F7FF;
        padding: 10px 15px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        h3{
            font-size: 12px;
            font-weight: 500;
            color: $heading-color;
            padding: 0px;
        }

        .toggle-switch {
            position: relative;
            width: 50px;
            height: 25px;
      
            input {
              opacity: 0;
              width: 0;
              height: 0;
            }
      
            .slider {
              position: absolute;
              cursor: pointer;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              background-color: #ccc;
              border-radius: 15px;
              transition: background-color 0.3s ease;
      
              &:before {
                position: absolute;
                content: "";
                height: 20px;
                width: 20px;
                border-radius: 50%;
                background-color: #fff;
                top: 50%;
                left: 3px;
                transform: translateY(-50%);
                transition: left 0.3s ease;
              }
            }
      
            input:checked + .slider {
              background-color: #32d1f5;
      
              &:before {
                left: calc(100% - 23px);
              }
            }
        }
    }
    .card-content{
        background: rgba(255, 241, 214, 0.40);
        padding: 10px 15px;

    }
    .card-list{
        ul{
            display: flex;
            align-items: flex-start;
            text-align: center;
            li{
                padding-bottom: 10px;
                width: 33.33%;
            }
            .total-dispute-txt{
               
                label{
                    font-size: 16px;
                    font-weight: 600;
                    display: block;
                    color: #FFA340;
                }
                span{
                    font-size: 12px;
                    font-weight: 700;
                    color: #666666;
                }
            }
            .gmv-txt{
                padding: 0px 10px;
                label{
                    font-size: 16px;
                    font-weight: 600;
                    color: #34C48B;
                    display: block;
                }
                span{
                    font-size: 12px;
                    font-weight: 400;
                    color: #666666;
                }
            }
            .di-recovery-txt{
                label{
                    font-size: 16px;
                    font-weight: 600;
                    color: #487CFD;
                    display: block;
                    
                }
                span{
                    font-size: 12px;
                    font-weight: 400;
                    color: #666666;
                }
            }
        }
    }
}
.payment-recovery-outer{
    .acc-heading-financer{
        h3{
            font-size: 18px;
            font-weight: 500;
            color: $heading-color;
        }
    }
    .dealer-payment-acc-sec{
        padding: 20px 20px 0;
        .dealer-amt-txt{
            display: flex;
            align-items: center;
            justify-content: space-between;
            label{
                font-size: 16px;
                font-weight: 700;
                color: $heading-color;
            }
            span{
                font-size: 14px;
                font-weight: 600;
                color: #3CC04D;
            }
        }
        .dispute-case-id-heading{
            font-size: 16px;
            font-weight: 500;
            color: $heading-color;
            padding-bottom: 10px;
            display: block;
        }
        .dealer-payment-detail-outer{
            .custom-control{
                 top: 5px;
            }
        .custom-control-label {
            color: rgba($heading-color, 0.7);
            font-size: 13px;

            &:before {
                width: 13px;
                height: 13px;
            }

            &:after {
                top: 0;
                width: 17px;
                height: 17px;
            }
        }

        .custom-control-input {
            width: 16px;
            height: 16px;
        }
    }
 .payment-type-date{
    .payment-title{
        font-size: 16px;
        font-weight: 400;
        color: $heading-color;
    }
    .payment-date{
        font-family: Poppins;
        font-size: 11px;
        font-style: italic;
        font-weight: 400;
        display: block;
        color: $heading-color;
    }
 }
 .dealer-payment-list{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 15px;
    .checkbox-txt{
        display: flex;
    }
    .payment-amount-input{
        width: 100px;
        border-radius: 8px;
        border: 1px solid $border-color;
        font-size: 14px;
        font-weight: 500;
        text-align: center;
        height: 30px;
    }
 }
}
}
.dispute-amp-breakup-outer{
   .accordion__panel{
    padding: 10px 0;
   }
    .acc-heading{
        h4{
            font-size: 18px;
            font-weight: 500;
            color: $heading-color;
        }
    }
    .amt-breakup-list{
        li{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 20px;
            border-bottom: 1px solid $border-color;
            color: $heading-color;

            .input-field {
                max-width: 100px;
                text-align: right;
                border: 1px solid #C1C1C1;
                height: 30px;
                border-radius: 6px;
                padding: 0px 8px;
            }
        }
        .amt-paid-txt{
            span{
                color: #3CC04D;
            }
        }
        .amt-to-be-recovered{
            span{
                color: #FE070B;
            }
        }
        .amt-pending-txt{
            span{
                color: #fa9119;
            }
        }
    }
    .remark-outer{
        padding: 20px 20px 0;
        textarea {
            resize: none;
          }
    }
    .recovery-title{
        font-size: 13px;
        font-style: italic;
        font-weight: 700;
        color: $heading-color;
        display: block;
        padding: 0px 20px 0px;
    }
    .amt-recovery-breakup-list{
        padding: 0px 20px 0px;
        ul{
            padding-left: 20px;
            li{
                display: flex;
                align-items: center;
                padding: 8px 0;
                justify-content: space-between;
                label{
                    width: 50%;
                }
                span {
                    text-align: right;
                    width: 150px;
                }
                .date-filed-txt{
                    width: 150px;
                }
                &.total-amt-txt{
                    font-weight: 700;
                    color: $heading-color;
                    padding-bottom: 30px;
                    label{
                       font-style: italic;
                    }
                }
            }
        }
    }
}

.additonal-doc-section{
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding-bottom: 15px;
    .additionl-doc-heading {
        display: flex;
        align-items: center;
        label {
            padding-right: 20px;
            font-size: 14px;
            font-weight: 400;
            color: $heading-color;
        }
        span {
            width: auto !important;
        }
    }
    .doc-upload-btn {
        cursor: pointer;
        position: relative;
        input {
            position: absolute;
            height: 100%;
            width: 100%;
            opacity: 0;
        }
    }
}


.add-doc-img-view {
    height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    margin-bottom: 20px;
    img {
        object-fit: cover;
        height: inherit;
        width: 100%;
    }
}
;@include ---4uev11z1pob.load-css("sass-embedded-legacy-load-done:49");