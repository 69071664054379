@use "sass:meta" as ---4uev11z1pob;.financer-quote-outer{
  .financer-quote-card{
    box-shadow: 0px 2px 4px 0px rgba(36, 39, 44, 0.06);
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    padding: 15px 40px 15px 20px;
    margin-bottom: 20px;
    .financer-deatil{
      display: flex;
      align-content: flex-start;
      justify-content: space-between;
      padding-bottom: 20px;
    }
    .finanacer-logo{
      background: $white;
      border: 1px solid #E0E0E0;
      border-radius: 5px;
      padding: 10px;
      max-width: 124px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      max-height: 52px;
    }
    .financer-car-detail{
      color: $heading-color;
      padding-left: 20px;
      span{
        font-size: 16px;
        font-weight: 500;
        display: block;
        line-height: normal;
       
      }
      label{
        font-size: 11px;
        font-weight: 400;

      }
    }
    .check-approve-status{
      display: flex;
      align-items: center;
      .approver-status{
        background:  rgba(59, 192, 77, 0.89);
        border-radius: 4px;
        height: 18px;
        color: $white;
        padding: 0px 10px;
        font-size: 10px;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;

      }
      .raject-status{
        background: #FF5858;
        border-radius: 4px;
        height: 18px;
        color: $white;
        padding: 0px 10px;
        font-size: 10px;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;

      }
    }
    .car-finance-detail{
      position: relative;
      ul{
        display: flex;
        margin: 0px -15px;
        li{
          padding: 0px 15px;
          span{
            font-size: 10px;
            font-weight: 400;
            line-height: 15px;
          }
          label{
            font-size: 12px;
            font-weight: 500;
            line-height: 18px;
            display: block;

          }
        }
      }
      &.quote-selection{
        ul{
          li{
            padding: 0px 30px;
          }
        }
      }
    }
    .quote-selectioin-checkbox{
      position: absolute;
      right: -50px;
      top: 10px;
    }
  }
}
.qutote-section{
  .financer-quote-card{
    padding-top: 5px;
  
  .car-finance-detail{
    ul{
      flex-wrap: wrap;
      li{
        padding-top: 7px !important;
        width: 25%;
        .form-input{
          width: 85% !important;
        }
      }
    }
  }
}
}
.amount-finance-outer{
  box-shadow: 0px 2px 4px 0px rgba(36, 39, 44, 0.06);
  border-radius: 8px;
  border: 1px solid #E0E0E0;
  padding: 20px;
  display: inline-block;
  h3{
    padding: 0px 0 10px;
    font-size: 14px;
    font-weight: 500;
    color: $heading-color;
  }
  .dp-amt-btn{
    display: flex;
    align-items: center;
    .btn-primary{
      min-width: auto;
      padding: 0px 25px;
      margin-left: 10px;
    }
    .single-select{
      width:200px;
    }
  }
}

.add-user-applicatiom-method{
  &.lead-detail-tabs{
   .nav-pills{
    padding: 20px 0;
    box-shadow: none;
    width: 100%;
    margin-left: 0px;
    
   }
   }
}
.applicatiom-method-heading{
  h3{
    font-size: 22px;
    line-height: 30px;
    color: #000;
    font-weight: 500;
    padding-bottom: 10px;
  }
}
.btn-bottom{
  border-top: 1px solid $border-color;
  padding-top: 30px;
  margin-top: 30px;
}
.out-of-town-charge{
  display: flex !important;
  align-items: center;
  .form-input{
    height: 38px !important;
    margin-left: 5px;
  }
}

.financier-pills{
  &.nav-pills {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .tab-list{
      &::after{
        display: none;
      }
      padding-top: 10px;
      padding-bottom: 20px;
    }
    .nav-item {
        a {
            background: #eff2f9;
            border-radius: 100px;
            font-weight: normal;
            font-size: 12px;
            line-height: 20px;
            color: $heading-color;
            height: 36px;
            padding: 0px 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
            cursor: pointer;
            &.active {
                background: #0bbddd;
                color: $white;
            }
        }
    }
}
}

.single-select{
  .quote-single-select__control{
    height: 38px !important;
  }
}

.soft-approved {
  background-color: #14b339; /* Light green background */
  color:  #e0f7e0; /* Darker green text */
  // font-weight: normal;
  padding: 3px 10px;
  border-radius: 15px;
  display: inline-block;
  font-size: 10px;
  margin-right: 10px;
}

.rejected {
background-color: #eb617f; /* Light green background */
color:  #e0f7e0; /* Darker green text */
// font-weight: normal;
padding: 3px 10px;
border-radius: 15px;
display: inline-block;
font-size: 10px;
margin-right: 10px;
}

.flexdisplay{
display:flex
}
;@include ---4uev11z1pob.load-css("sass-embedded-legacy-load-done:47");