@use "sass:meta" as ---4uev11z1pob;.acc-item-financer {
    margin-bottom: 20px;
    border-radius: 8px;
    border: 1px solid $border-color;

    .acc-panel-finaner {
        background: $white;
        box-shadow: 0px 2px 4px 0px rgba(36, 39, 44, 0.06);
        border-radius: 0 0 8px 8px;
        padding-bottom: 20px;
    }

    .acc-heading-financer {

        background: #E3E7F1;
        border-radius: 8px;
        width: 100%;
        padding: 10px;

        .financer-name-no-date {
            display: flex;
            align-items: center;
            padding: 0px 30px;

            h3 {
                color: $heading-color;
                font-size: 16px;
                font-weight: 500;
                overflow: hidden;
                display: block;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            .date-time-txt {
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                color: rgba(0, 0, 0, .6);
            }

            &.financer-name-date {
                justify-content: space-between;

                h3 {
                    width: calc(100% - 180px);

                }
            }
        }

        .custom-control {
            position: absolute;
            top: 2px;
        }


        .accordion__button {
            // background: transparent;
            // width: 100%;
            // border: 0px;
            // text-align: left;
            position: relative;
            cursor: pointer;

            &:after {
                content: "\e907";
                font-family: dc !important;
                position: absolute;
                right: 0;
                font-size: 20px;
                bottom: 1px;
            }
        }

        .accordion__button[aria-expanded="true"]::after,
        .accordion__button[aria-selected="true"]::after {
            content: "\e90c";
            font-family: dc !important;
        }

        .checkbox-radius {
            .custom-control-label {
                &::before {
                    border-radius: 100%;
                    background: #D1D1D1;
                    border-color: #D1D1D1;
                    color: #fff;
                }

                &::after {
                    background: transparent;
                    content: "\e927";
                    font-family: "dc" !important;
                    text-align: center;
                    font-size: 12px;
                    line-height: 18px;
                    color: #ffffff;
                    font-weight: bold;
                }
            }

            .custom-control-input:focus:not(:checked)~.custom-control-label::before {
                border-color: #D1D1D1;
            }

            .custom-control-input:checked~.custom-control-label::before {
                border-radius: 100%;
                border-color: #0bbddd;
                background-color: #0bbddd;
                color: #fff;
            }
        }
    }
}

.financer-sending-mode {
    padding-right: 20px;
    padding-left: 20px;

    h4 {
        padding-bottom: 15px;
        font-size: 15px;
        font-weight: 500;
        color: $heading-color;
    }
}

.email-templete-sec {
    .acc-item-financer {
        .acc-heading-financer {
            padding: 10px;

            .heading-icn {
                padding: 0px;
                border-bottom: 0px;
            }
            .accordion__button{
                &::after{
                    bottom: -3px;
                }
            }
        }
    }
    .send-email-heading{
        color: $accent-color;
        font-size: 17px;
        border-bottom: 1px solid $border-color;
        padding-bottom: 10px;
        margin-bottom: 20px;
         display: block;
    }
}
.email-templete-inner-accordain{
    padding: 0px 20px;
    .accordion__button{
        padding: 10px 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        font-weight: 500;
        font-size: 16px;
        line-height: normal;
        color: $heading-color;
            
    }
}
.soft-approval-filled-outer{
    h3{
        padding: 0px;
    }
    .acc-item-financer {
        .acc-heading-financer {
            .accordion__button{
                &::after{
                    bottom: 6px;
                }
            }
        }
    }   
    .soft-approvel-sec{
        padding: 20px 20px 0px;
    }
}
.check-uncheck-img{
    position: absolute;
    left: 0px;
}
.loan-expected-inner-tab{
    &.nav-pills{
        box-shadow: none;
        width: 100%;
        margin-left: 0px;
        padding: 10px 0px 20px;
        .nav-item{
            a{
                &.active {
                    background: #3BC04D;
                    color: #fff;
                }
            }
        }
    } 
}
.financer-tooltip{
    position: absolute;
    right: -10px;
    bottom: 0px;
    top: inherit;
    
}
;@include ---4uev11z1pob.load-css("sass-embedded-legacy-load-done:42");