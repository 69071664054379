@use "sass:meta" as ---4uev11z1pob;.top-heading-outer {
    background: $white;
    padding: 0px 15px;

    .sub-heading {
        border-bottom: 3px solid $accent-color;
        font-weight: 600;
        color: rgba($accent-color, 0.87);
        display: inline-block;
    }
}

.financier-bx-table-outer {
    table {
        tr {
            td {
                span {
                    display: block;
                }
            }
        }
    }
}

.financier-detail-outer {
    background: $white;
    padding: 15px;
    margin-top: 20px;
    border-radius: 8px;

    .financier-top-filter {
        display: flex;
        align-items: center;

        fieldset {
            width: 20%;
            margin-right: 10px;
        }

        .single-select {

            .react-select__value-container--has-value .css-1wa3eu0-placeholder,
            .react-select__value-container--has-value .react-select__placeholder {
                top: -5px;

            }

            .react-select__control--menu-is-open .css-1wa3eu0-placeholder,
            .react-select__control--menu-is-open .react-select__placeholder {
                top: -5px;
            }
        }

        .material {
            input.form-input {
                height: 42px;
            }
        }

    }
}

.financier-sub-filter-list {
    margin-top: 40px;
}

.toggle-switch-outer {
    width: 150px;
    .toggleApp {
        // width: 350px;
        margin: auto;
        margin-left: 10px;
    }

    .ToggleSwitch {
        background-color: #dbdfea;
        height: 18px;
        width: 35px;
        border-radius: 55px;

        &.active {
            background-color: $accent-color;
        }
    }

    .ToggleSwitch .knob {
        position: relative;
        width: 16px;
        height: 16px;
        background: $white;
        border-radius: 50%;
        left: 0em;
        transition: left 0.3s ease-out;
        top: 1px;

        &.active {
            background: $white;

            &::after {
                content: "";
                background: red;
            }
        }
    }

    .ToggleSwitch .knob.active {
        left: 18px;
    }

    .toggle-status {
        margin-left: 45px;
        margin-top: -16px;
        font-size: 12px;
        font-weight: normal;
    }

    .knob~.toggle-status {
        color: $heading-color;
    }

    .knob.active~.toggle-status {
        color: $heading-color;
    }
}
.swith-txt-outer{
    padding: 30px 0px 5px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h3{
        font-weight: 600;
        color: #000;
    }
}

.templete-list-filed-outer {
    display: flex;
    align-items: center;
    .list-left-panel {
        padding: 20px;
        border: 1px solid $border-color;
        width: 25%;
        max-height: 400px;
        overflow-y: auto;

        h2 {
            font-weight: 500;
            font-size: 15px;
            padding: 0px;
            margin: 0px;
        }

        ul {
            padding-bottom: 10px;

            li {
                padding-left: 5px;
                line-height: 28px;
                cursor: pointer;
                font-size: 13px;
            }

            .selected-attribute {
                border-radius: 2px;
                background-color: #0bbddd;
                color: white;
                font-weight: 600;
            }
        }
    }

    .add-remove-arrow {
        padding: 0px 20px;

        li {

            width: 42px;
            height: 42px;
            text-align: center;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 5px;
            font-size: 30px;
            border: 1px solid $border-color;
        }
    }
    .text-filed-select-area{
        width: calc(100% - 33%);
        textarea{
            width: 100%;
            height: 400px;
            border: 1px solid $border-color;
            resize: none;
            padding: 20px;
        }
    }
}
.update-btn{
    padding-top: 20px;
    justify-content: center;
    .btn-primary, .btn-line{
        min-width: 160px;
    }
}
.select-option-status{
    .react-select__single-value{
        width: calc(100% - 20px) !important;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .status-txt{
            color: rgba($heading-color, 0.5);
            font-style: italic;
            padding-right: 5px;
        }
    }
    .react-select__option{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .status-txt{
            color: rgba($heading-color, 0.5);
            font-style: italic;
            padding-right: 5px;
        }
    }
}

.required-border {
    border: 1px solid red !important;
    border-radius: 8px !important;
}
;@include ---4uev11z1pob.load-css("sass-embedded-legacy-load-done:29");