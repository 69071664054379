@use "sass:meta" as ---4uev11z1pob;.mrp-contaier {
    max-width: 550px;
    margin: 0 auto;
    padding: 20px 10px;
    background: $white;
  
    .single-select {
  
      .react-select__value-container--has-value .css-1wa3eu0-placeholder,
      .react-select__value-container--has-value .react-select__placeholder {
        position: absolute;
        top: -7px;
        background: $white;
        transition: all 0.15s ease-in-out;
        margin-left: -2px;
        font-size: 12px;
        padding: 0 3px;
        width: auto;
      }
  
      .react-select__control--menu-is-open .css-1wa3eu0-placeholder,
      .react-select__control--menu-is-open .react-select__placeholder {
        position: absolute;
        top: -7px;
        background: $white;
        transition: all 0.15s ease-in-out;
        margin-left: -2px;
        font-size: 12px;
        padding: 0 3px;
        width: auto;
      }
  
      .css-g1d714-ValueContainer,
      .react-select__value-container,
      .react-select__value-container--has-value {
        overflow: inherit !important;
        background: $white;
        padding-left: 13px;
        padding-right: 13px;
      }
  
      .css-26l3qy-menu {
        margin-top: 0px;
      }
  
      .is-diabled--is-disabled {
  
        .is-diabled__value-container,
        .is-diabled__control--is-disabled {
          background-color: #f8f8f8;
          min-height: 42px;
          border-radius: 8px;
        }
      }
    }
  
    .btn-primary {
      min-width: auto;
      width: 100%;
    }
    .quote-list {
      height: 190px;
      // overflow-y: auto;  
      margin-bottom: 20px;
    }
    .link-btn {
      cursor: pointer;
    }
  }
  
  .mrp-fileds {
  padding-top: 20px;
  
    h3 {
      font-size: 20px;
      line-height: 30px;
      color: $heading-color;
      font-weight: 500;
      display: block;
      padding-bottom: 18px;
    }
  
    fieldset {
      padding-bottom: 15px;
    }
  }
  .avg-min-mrp-card, .avg-financier-mrp-card{
    background: #47BEDD;
    border-radius: 8px;
    padding: 15px 10px;
    margin-top: 20px;
    margin-bottom: 25px;
    background: url(../../images/mrp-shape.svg) top right no-repeat #47BEDD;
    ul{
      li{
         display: flex;
         align-items: center;
         justify-content: space-between;
         color: $white;
         padding-bottom: 15px;
         &:last-child{
          padding-bottom: 0px;
         }
        label{
          font-weight: 400;
          font-size: 14px;
        }
        span{
          font-weight: 600;
          font-size: 16px;
        }
      }
    }
  }
  .avg-financier-mrp-card{
    background-color: #FFF1D6;
    margin-top: 5px;
    display: inline-block;
    width: 100%;
    ul{
      li{
        color: $heading-color;
      }
    }
  }
  .financer-card-outer{
    display: inline-block;
    width: 100%;
    .financer-detail-card{
      background: $white;
      border: 1px solid #E0E0E0;
      box-shadow: 0px 2px 4px rgba(36, 39, 44, 0.06);
      border-radius: 8px;
      padding: 10px;
      margin-bottom: 15px;
      .financer-img-txt{
        display: flex;
        border-bottom: 1px solid $border-color;
          padding-bottom: 10px;
          margin-bottom: 10px;
        .finacer-img{
          height: 75px;
          width: 124px;
          border: 1px solid #E0E0E0;
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;
        }
        .financer-detail-txt{
          padding-left: 20px;
          
          .finacer-name{
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
            color: $heading-color;
            display: block;
          }
          .car-details{
            font-weight: 400;
            font-size: 11px;
            line-height: 22px;
            color: $heading-color;
            display: block;
          }
        }
  
      }
    
    }
  
    .financer-more-detail{
      display: flex;
      align-items: center;
      justify-content: space-between;
      .city-region{
        span{
        display: block;
        font-weight: 400;
        font-size: 11px;
        line-height: 22px;
        color: $heading-color;
        }
      }
      .finacer-mrp-txt{
        span{
          font-weight: 400;
          font-size: 11px;
          line-height: 20px;
          color: $heading-color;
          display: block;
        }
        .price-txt{
          font-weight: 500;
          font-size: 12px;
          line-height: 20px;
          color: $heading-color;
          text-align: right;
        }
      }
    }
  }
  .avg-market-txt-card{
    span{
      text-decoration: underline;
    }
  }
  
  .avg-market-car-detail-card{
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    box-shadow: 0px 2px 4px rgba(36, 39, 44, 0.06);
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 10px;
    ul{
      li{
        color: $heading-color;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 8px;
        label{
          font-weight: 400;
          font-size: 11px;
          line-height: 16px;
        }
        span{
          font-weight: 700;
          font-size: 11px;
          line-height: 16px;
          text-align: right;
        }
      }
    }
    .url-txt{
      color: $text-color;
      text-decoration: underline;
      font-weight: 400;
      font-size: 10px;
      line-height: 15px;
    }
  }
  
  .datepicker {
    input {
        width: 100%;
        border: 1px solid #bbbbbb;
        border-radius: 8px;
        box-shadow: 0 0 0;
        width: 100%;
        padding: 11px 15px;
        font-weight: 400;
        font-size: 12px;
        background: url(../../images/date.svg) no-repeat right 7px center;
        &:disabled {
            background: url("../../images/date.svg") no-repeat right 19px center rgba(239, 239, 239, 0.3);
            border-color: rgba(118, 118, 118, 0.3);
          }
    }
  
    .react-datepicker__header__dropdown {
        padding: 10px;
        select {
            appearance: auto;
            background: transparent;
            border: 0px;
        }
    }
  }
  
  .timepicker-filed {
    input {
        width: 100%;
        border: 1px solid #bbbbbb;
        border-radius: 8px;
        box-shadow: 0 0 0;
        width: 100%;
        padding: 11px 15px;
        font-weight: 400;
        font-size: 12px;
        background: url(../../images/time_icn.svg) no-repeat right 7px center;
    }
    .react-datepicker__header__dropdown {
        padding: 10px;
        select {
            appearance: auto;
            background: transparent;
            border: 0px;
        }
    }
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    background-color: #0bbddd !important;
  }
  
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    background-color: #0bbddd !important;
  }
  
  .error-msg,.error {
    color: red;
    margin-bottom: 10px;
    font-size: 12px;
  }
  
  
  .react-select__indicator-separator{
    display: none;
  
  }
  
  .react-datepicker-wrapper{
    width: 100%;
  }
  
  .mmvdetails-vehicle{
    display: flex;
    align-items: center;
    padding-left: 38px;
    padding-bottom: 20px;
    li{
      font-size: 13px;
      color: $heading-color;
      font-weight: 400;
      position: relative;
      margin-right: 30px;
      &::after{
        content: "";
        background-color: $heading-color;
        height: 2px;
        width: 10px;
        position: absolute;
        top: 9px;
        right: -20px;
      }
      &:last-child{
        &::after{
          display: none;
        }
      }
    }
  }
  .mmvdetailsph-title{
    font-size: 20px;
    font-weight: 500;
    color: $heading-color;
  }
  .mmvdetails-calculationdiv{
    padding: 15px 0px;
    h2{
      font-size: 16px;
      font-weight: 500;
      color: $heading-color;
      padding-bottom: 10px;
    }
    table{
      border: 1px solid $border-color;
      tr{
        border-bottom: 1px solid $border-color;
        td{
          padding: 15px;
          font-size: 14px;
          color: $heading-color;
          &:last-child{
            text-align: right;
          }
        }
      }
    }
  }
  
  .mmvdetails-finalrange{
    background-color: #f7f8fa;
    border-radius: 8px;
    padding: 15px;
    display:block;
    text-align: center;
    margin-top: 10px;
    h2{
      font-size: 22px;
      font-weight: 500;
      color: $heading-color;
    }
    span{
      font-size: 14px;
      color: $heading-color;
      display: block;
      width: 100%;
      padding: 20px 0 8px;
    }
  }
  
  .price-title{
    color: $text-color;
    font-weight: bold;
    text-align: center;
  }
  .display-block {
    display: block;
  }
  
  .display-none {
    display: none;
  }
  
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 9;
  
    .modal-main {
      position: fixed;
      background: white;
      height: auto;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .close_icn {
      position: absolute;
      top: 20px;
      right: 20px;
      background: none;
      border: none;
      font-size: 21px;
      cursor: pointer;
    }
    .modal-body {
      max-height: 450px;
      overflow-y: auto;
      width: calc(100% + 80px);
      margin-left: -40px;
      padding: 0px 40px;
    }
  }
  
  .overflow-hidden {
    overflow: hidden;
  }
  
  .view-timeline-popup {
    .modal-body {
      max-height: 450px;
      overflow-x: hidden;
      overflow-y: hidden;
      width: calc(100% + 80px);
      margin-left: -40px;
      padding: 0px 40px;
    }
    .modal-main {
      border-radius: 8px;
      width: 530px;
      padding: 40px 40px;
      h2 {
        padding: 0px 0 24px 0px;
        font-weight: 600;
        font-size: 22px;
        line-height: 30px;
      }
    }
    .timeline-history-tabel {
      max-height: 230px;
      overflow-y: auto;
      width: calc(100% + 80px);
      margin-left: -40px;
      padding: 0px 40px;
    }
    table {
      table-layout: fixed;
      border-collapse: separate;
      border-spacing: 0 10px;
      tbody {
        tr {
          background: #eff2f9;
          td {
            font-size: 11px;
            line-height: 18px;
            color: rgba($heading-color, 0.6);
            padding: 14px 20px;
  
            &:nth-child(1) {
              width: 80px;
              padding-left: 0px;
              background: $white;
            }
            &:nth-child(2) {
              width: calc(100% - 80px);
              border-radius: 8px;
            }
            &:nth-child(3) {
              border-radius: 0 8px 8px 0;
            }
  
            .date {
              font-weight: normal;
              font-size: 12px;
              line-height: 20px;
              color: $heading-color;
              display: block;
            }
            .time {
              font-size: 10px;
              line-height: 20px;
              color: rgba($heading-color, 0.6);
              display: block;
            }
            .heading {
              font-weight: normal;
              font-size: 13px;
              line-height: 20px;
              color: $heading-color;
              display: block;
              font-weight: 400;
              word-wrap: break-word;
            }
            .subheading {
              font-size: 11px;
              line-height: 18px;
              color: rgba($heading-color, 0.6);
              display: block;
            }
          }
          &:hover {
            background: #bff8fc;
          }
        }
      }
    }
    &.doc-history-timeline {
      table {
        tbody {
          tr {
            td {
              padding: 14px 15px;
              &:nth-child(3) {
                border-radius: 0 !important;
                width: 90px;
              }
              &:nth-child(4) {
                border-radius: 0 8px 8px 0 !important;
                vertical-align: text-bottom;
                font-size: 18px;
              }
            }
          }
        }
      }
    }
    .tab-line {
      .tab-list {
        display: flex;
        align-items: center;
        padding-bottom: 20px;
        &::after {
          display: none;
        }
      }
    }
  }
  
  .view-loandata-popup {
    .modal-main {
      width : fit-content !important
    }
  }
  
  .total-loan-amount-warning-popup {
    .modal {
      background: none !important;
      .modal-main {
        border: 1px solid #8c8c8c;
      }
    }
  }
  
  
  .view-login-popup{
    .modal-body {
      max-height: 450px;
      overflow-x: hidden;
      overflow-y: hidden;
      margin-left: -40px;
      padding: 0px 40px;
    }
    .modal-main {
      border-radius: 8px;
      max-width: 400px;
      padding: 40px 40px;
      h2 {
        padding: 0px 0 24px 0px;
        font-weight: 600;
        font-size: 22px;
        line-height: 30px;
      }
    }
  }
  
  .remark-heading-txt {
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    color: $heading-color;
    padding: 30px 0 13px;
    display: block;
  }
  
  .mark-lost-popup {
    .modal-main {
      border-radius: 8px;
      width: 480px;
      padding: 40px 40px;
      h2 {
        padding: 0px 0 20px 0px;
        font-weight: 600;
        font-size: 22px;
        line-height: 30px;
      }
    }
    .modal-body {
      padding-top: 7px;
      overflow-y: inherit;
    }
    fieldset {
      margin-bottom: 20px;
    }
  }
  
  .image-timeline-popup {
    .modal-body {
      overflow-y: auto;
    }
    table {
      table-layout: fixed;
      border-collapse: separate;
      border-spacing: 0 10px;
      tbody {
        tr {
          background: #eff2f9;
          td {
            font-size: 11px;
            line-height: 18px;
            color: rgba($heading-color, 0.6);
            padding: 14px 20px;
            height: auto;
  
            &:nth-child(1) {
              width: 53px;
              padding-left: 0px;
              background: $white;
            }
            &:nth-child(2) {
              width: 230px;
              border-radius: 8px 0 0 8px;
            }
            &:nth-child(3) {
              border-radius: 0 8px 8px 0;
              width: 120px;
            }
          }
        }
      }
    }
  }
  
  .disbursal-detail-log {
    ul {
      li {
        span {
          word-break: break-all;
        }
      }
    }
  }
  
  
  .history-list-outer {
    display: flex;
    justify-content: space-between;
  
  .user-name {
    max-width: 70px;
  }
  
  }
  
  
  .p-relative {
  position: relative;
  }
  
  .view-more-btn {
    position: absolute;
    right: -38px;
    font-size: 37px;
    top: 50%;
    transform: translate(0px, -50%);
    cursor: pointer;
  }
  
  .view-more-txt {
    border-top: 1px solid $border-color;
    margin-top: 10px;
  }
  
  .rejection-reason-list {
    margin-left: 10px;
    li {
      list-style: unset;
    }
  }
  .button-like-link {
    background: none;
    border: none;
    font-size: 12px;
    color: $accent-color; /* Link color */
    text-decoration: none;
    cursor: pointer;
    padding-top: 10px;
}
.checkbox-external input[type="checkbox"] {
  -webkit-appearance: none; /* Remove default checkbox style in WebKit browsers */
  -moz-appearance: none; /* Remove default checkbox style in Firefox */
  appearance: none; /* Remove default checkbox style */
  width: 20px;
  height: 20px;
  border: 1px solid #ccc;
  border-radius: 3px;
  outline: none;
  cursor: pointer;
  margin-right: 10px;
  opacity: 1;
}
.checkbox-external input[type="checkbox"]:checked::before {
  content: '\2713'; /* Unicode character for check mark */
  display: block;
  text-align: center;
  line-height: 16px;
}
.calculation-detail-outer{
  padding: 20px !important;
}
.text-checkbox{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
  h2{
    padding-bottom: 0px !important;
  }
  input{
    appearance: auto !important;
    height: 20px;
    width: 20px;
  }
}
.caluction-popup-outer{
  .modal-main{
    max-width: initial !important;
    width: 500px !important;
  }
  .modal-body{
    overflow-y: auto !important;
  }
}

.caluction-popup-outer{
  .modal-main{
    max-width: initial !important;
    width: 700px !important;
  }
  .modal-body{
    overflow-y: auto !important;
  }
}

.fvm-input-outer{
  background: transparent !important;
  .fvm-percent-value{
    font-size: 20px;
    color: $heading-color;
    font-weight: 500;
    display: block;
    text-align: center;
    width: 100%;
    padding-bottom: 0px !important;
    padding-top: 0px !important;
  }
  span{
    padding: 10px !important;
  }
  h2{
    padding-bottom: 0px !important;
  }
}
;@include ---4uev11z1pob.load-css("sass-embedded-legacy-load-done:46");