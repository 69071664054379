@use "sass:meta" as ---4uev11z1pob;.coustmer-timeline-popup{
  .modal-main{
    width: 600px;
    .financer-tab-outer{
      overflow-x: auto;
      width: 100%;
      .nav-item{
        a{
          white-space: nowrap;
        }
      }
    }
    table{
      tbody{
        tr{
          td{
            height: auto;
            &:nth-child(2){
              border-radius: 8px 0 0 8px;
            }
            &:nth-child(3){
              text-align: right;
              width: 140px;

            }
          }
        }
      }
    }
  }
}
.btn-skip{
  background: transparent;
  border: 0px;
  color: $accent-color;
  &[disabled], &[disabled]:hover  {
    background: transparent;
    color: rgba($heading-color, 0.2);
  }
}



.credit-complete-date{
  align-items: center;
  .custom-control-label{
    padding-left: 20px;
  }
  .credit-date-picker{
    width: 100%;
  }
}

.credit-approvel-acc-outer{
  .soft-approvel-sec{
  padding: 20px 10px 0;
  }
  .acc-item-financer {
  .acc-heading-financer {
    padding: 10px;
    cursor: pointer;
  }  
  }

}
.financer-loan-approve-outer{
  .nav-pills{
    box-shadow: none !important;
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
  }
  .financer-loan-tab-outer{
    padding: 10px 0 0;
   
  }
  .financer-name-date{
    h3{
    font-size: 16px;
  }
}
}

.tippy-tooltip-content{
  .tooltip-data{
    max-height: 400px;
    overflow: auto;
  }
}

.loan-detail-form-label{
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 500;
  color: $heading-color;
}
.f-16{
  h3{
  font-size: 16px !important;
}
}
;@include ---4uev11z1pob.load-css("sass-embedded-legacy-load-done:48");